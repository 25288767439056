import { Box, Container, Flex, Grid, GridItem, Text, useMediaQuery } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import OfficeInfo from 'components/pages/ContactUs/OfficeInfo';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { getLinkToMore } from 'utils/linkToMore';
import { normalizeEditorialSpotItems } from 'utils/normalization/editorialSpot';

import { colorScheme, getColorScheme } from '../../lib/colorScheme';
import { LinkType } from '../../lib/enums/LinkType';
import {
  Contact,
  ContentPage,
  EditorialSpots as EditorialSpotsModel,
  Office,
  PDFEditorialSpotItem,
} from '../../models';
import { Link } from '../base/Link';
import { HyphenResult } from '../hyphen/hyphenation';
import { OverviewItem } from './OverviewItem';
import { PersonOverviewItem } from './PersonOverviewItem';

interface EditorialSpotsProps {
  model: EditorialSpotsModel;
}

export const EditorialSpots: FunctionComponent<EditorialSpotsProps> = ({ model }) => {
  const translations = withTranslations();
  const [isSmallScreen] = useMediaQuery('(max-width: 1024px)');
  const router = useRouter();
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  const scheme = colorScheme[model.elements.backgroundColor?.value[0].codename as keyof typeof colorScheme];
  const paddings = model.elements.paddings?.value.map((value) => value.codename);
  const items = normalizeEditorialSpotItems(model, translations, slug as string[]);
  const headlineColor = getColorScheme(
    model.elements.backgroundColor?.value[0].codename,
    model.elements.headlineColor?.value[0]?.codename,
  );
  // const enableHyphenHeading = model.elements.enableHyphenationForHeading.value[0]?.codename == 'yes';
  // const enableHyphenSummary = model.elements.enableHyphenationForSummary.value[0]?.codename == 'yes';

  //TODO: after getting localization from url, apply different localization, or process.env.NEXT_PUBLIC_DEFAULT_LOCALE! if empty
  const subHeadline = model.elements.subheadline?.value;
  const linkText =
    model.elements.category.value[0] === undefined
      ? ''
      : translations.multiple(model.elements.category.value[0].codename);
  const ariaLinkText = model.elements.cta.value && model.elements.cta.value + ` ${linkText}`;

  const formModalButtonText = model.elements.formModalCtaButtonText?.value || '';
  const formModal = model.elements.formModalCtaFormModal?.linkedItems;
  const shouldShowPeople = model.elements.category.value[0]?.codename === 'people' && items && items.length > 0;

  const shouldShowOffices = model.elements.category.value[0]?.codename === 'office' && items && items.length;
  const linkToMore = getLinkToMore(
    model.elements.category.value[0]?.codename,
    model.elements.showcasedContent.linkedItems[0] as ContentPage | Contact | Office | PDFEditorialSpotItem,
    translations,
  );
  // console.log('editorial item ',model)
  const hyphenHeadline = HyphenResult(model.elements.headline.value, router.locale);
  const showCountry = model.elements.showCountryForContact.value[0]?.codename;
  const getOverviewItems = (personItemAdjustWidth?: boolean) => {
    if (shouldShowPeople) {
      return items.map((person, index) => (
        <GridItem
          key={index}
          as='li'
          flexGrow={1}
          w={personItemAdjustWidth ? 'min-content' : ''}
          px={{ base: 'xs', lg: 0 }}
          mt={{ base: 'xs', lg: 's' }}
        >
          <PersonOverviewItem
            editorialSpotsId={model.system.id}
            contact={person as Contact}
            formModalButtonText={formModalButtonText}
            formModal={formModal[0]}
            showCountry={showCountry}
          />
        </GridItem>
      ));
    }
    if (shouldShowOffices) {
      return items.map((office, index) => {
        const officeModel = office as Office;
        return (
          <GridItem key={index} as='li' flexGrow={1} px={{ base: 'xs', lg: 0 }}>
            <OfficeInfo
              address={
                createRichTextHtmlResolver(nodeParser).resolveRichText({
                  element: officeModel.elements.address,
                }).html
              }
              city={officeModel.elements.city.value}
              country={officeModel.elements.country.value[0].name}
              cvr={officeModel.elements.taxNumber.value}
              description={
                createRichTextHtmlResolver(nodeParser).resolveRichText({
                  element: officeModel.elements.info,
                }).html
              }
              email={officeModel.elements.email.value}
              id={officeModel.system.id}
              isMain={!!officeModel.elements.isHeadOffice.value[0]?.name}
              officeName={officeModel.elements.officeName.value || null}
              phone={officeModel.elements.phoneNumber.value}
            />
          </GridItem>
        );
      });
    }
    return items.map((item, index) => (
      <GridItem key={index} as='li' flexGrow={1} px={{ base: 'xs', lg: 0 }}>
        <OverviewItem
          model={item}
          singleItem={items.length === 1}
          numberOfItems={items.length}
          hideTag={model.elements.hideTag?.value[0]?.codename}
          // enableHyphenHeading={enableHyphenHeading}
          // enableHyphenSummary={enableHyphenSummary}
        />
      </GridItem>
    ));
  };
  const setPaddings = () => {
    const top = paddings.filter((item) => item.includes('top')).join(',');
    const bottom = paddings.filter((item) => item.includes('bottom')).join(',');
    return {
      pt: top.includes('all') ? 0 : top.includes('half') ? { base: 'xs', lg: 's' } : { base: 's', lg: 'l' },
      pb: bottom.includes('all') ? 0 : bottom.includes('half') ? { base: 'xs', lg: 's' } : { base: 's', lg: 'l' },
    };
  };

  return (
    <Box
      id={model.system.codename}
      w='100vw'
      maxW={moduleMaxWidth}
      mx='auto'
      bg={scheme.base}
      bgGradient={
        items.length === 1 &&
        'backgroundWidth' in items[0] &&
        items[0].backgroundWidth === 'partial' &&
        items[0].mediaPosition === 'start'
          ? {
              base: `linear(to-t, white 70%, ${scheme.base} 0%)`,
              lg: `linear(to-r, white 35%, ${scheme.base} 0%)`,
              xl: `linear(to-r, white 45%, ${scheme.base} 0%)`,
              '2xl': `linear(to-r, white 50%, ${scheme.base} 0%)`,
              '3xl': `linear(to-r, white 50%, ${scheme.base} 0%)`,
            }
          : {}
      }
    >
      <Container flexDir='column' sx={setPaddings()} px={{ base: '0', lg: 'l' }}>
        {/* --------- HEADLINE AND VIEW MORE ---------  */}
        <Flex flexDir={'row'} alignItems='center' justifyContent='space-between' gap={{ base: 0, lg: 'xs' }}>
          {!!model.elements.headline.value.length && (
            <Text
              as='h2'
              textStyle={{ base: 'mobile.body.l', md: 'desktop.h5' }}
              px={{ base: 'xs', lg: 0 }}
              color={headlineColor?.headline}
            >
              {hyphenHeadline}
            </Text>
          )}

          {linkToMore !== '' && !!model.elements.cta.value.length && (
            <Link
              ariaLabel={ariaLinkText}
              href={linkToMore}
              type={LinkType.SecondaryButton}
              iconName='chevronRightForLink'
              locale={router.locale}
            >
              {model.elements.cta.value}
            </Link>
          )}
        </Flex>
        {!!model.elements.subheadline.value.length && (
          <Text as='h3' textStyle={{ base: 'mobile.body.s', md: 'desktop.body.l' }} pt={{ base: 'xs', lg: 'xs' }}>
            {subHeadline}
          </Text>
        )}
        {/* --------- HEADLINE AND VIEW MORE END ---------  */}

        {/* --------- ITEMS ---------  */}
        {items.length < 3 || shouldShowOffices || !isSmallScreen ? (
          <Grid
            as='ul'
            listStyleType='none'
            templateColumns={{
              base: 'repeat(1, 1fr)',
              lg: `repeat(${items.length}, 1fr)`,
            }}
            gap={{ base: shouldShowOffices ? 's' : '32px', lg: 'l' }}
            mt={model.elements.subheadline.value.length ? { base: 'xs', lg: 'xs' } : { base: 's', lg: 's' }}
          >
            {getOverviewItems()}
          </Grid>
        ) : (
          <Flex
            mt={{ base: 's', lg: 'l' }}
            w='100%'
            as='ul'
            listStyleType='none'
            flexDir={'row'}
            pb='s'
            overflow='auto'
            sx={{
              '::-webkit-scrollbar': {
                bg: scheme.baseAccent,
                height: '3px',
              },
              '::-webkit-scrollbar-thumb': {
                bg: 'cyan.web',
              },
            }}
          >
            {getOverviewItems(true)}
          </Flex>
        )}
        {/* --------- ITEMS END ---------  */}
      </Container>
    </Box>
  );
};
